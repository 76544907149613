// 设备信息
<template>

    <div class='deviceInfoList baseBg'>
        <backUp></backUp>
        <!-- 头部搜索 -->
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     label-width="80px"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="设备编号">
                    <el-input v-model="searchForm.qrCode"
                              placeholder="请选择设备编号"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="SN码"
                              prop="serialNo">
                    <el-input v-model="searchForm.serialNo"
                              placeholder="请输入SN码"
                              size="normal"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="物料状态"
                              prop="matterStatus">
                    <SelectBomStatus v-model="searchForm.matterStatus"
                                     :empTypeId="null"
                                     placeholder="请选择物料状态"></SelectBomStatus>
                </el-form-item>
                <el-form-item label="检测状态"
                              prop="checkStatus">
                    <SelectCheckStatus v-model="searchForm.checkStatus"
                                       placeholder="请选择检测状态"></SelectCheckStatus>
                </el-form-item>
                <el-button type="primary"
                           @click="getList">搜索</el-button>
            </el-form>
        </div>

        <!-- 表格内容 -->
        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData.data"
                          border
                          :height="tableHeight"
                          stripe>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     align="center"
                                     :label="col.label"
                                     :min-width="col.width">
                        <template slot-scope='scope'>
                            <el-image @click="handleImgClick(`${PATH}/file/view/?file=${scope.row[col.id]}&access-token=${token}`)"
                                      style="width: 100px; height: 100px"
                                      fit="scale-down"
                                      :src="`${PATH}/file/view/?file=${scope.row[col.id]}&access-token=${token}`"
                                      v-if="col.id === 'qrCodePath' && scope.row[col.id]" />
                            <span v-else-if="col.id === 'flowMethod'">{{scope.row[col.id].text ? scope.row[col.id].text : scope.row[col.id]}}</span>
                            <span v-else-if="col.id === 'matterStatus'">{{scope.row[col.id].text ? scope.row[col.id].text : scope.row[col.id]}}</span>
                            <span v-else-if="scope.row[col.id]">{{scope.row[col.id]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     min-width="180">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看</el-link>
                            <el-link type="danger"
                                     @click="exportQR(scope.row)">导出</el-link>
                        </template>
                    </el-table-column>
                </el-table>

                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
        <!-- 导出二维码弹框 -->
        <exportQRcode v-if="exportQRcodeVisible"
                      :row="row"
                      @close="closeexportQRcode"></exportQRcode>

        <!-- 查看大图 -->
        <el-image-viewer v-if="showViewer"
                         :on-close="closeViewer"
                         :url-list="viewerImgList" />
    </div>

</template>

<script>
import SelectCheckStatus from "../../components/commonSelect/selectCheckStatus";
import SelectBomStatus from "../../components/commonSelect/selectBomStatus";
import exportQRcode from "./components/pop_exportQRcode";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/stockManage.js";
import backUp from "@/components/backUp";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
    name: "deviceInfoList",

    props: [],

    components: {
        SelectCheckStatus,
        SelectBomStatus,
        exportQRcode,
        Pagination,
        backUp,
        ElImageViewer,
    },

    data() {
        return {
            PATH: window.configPath.baseUrl,
            token: this.commonJs.getCookie("ERPToken"),

            showViewer: false, // 缩略图展示框是否显示
            viewerImgList: [], // 预览图片列表
            searchForm: {
                qrCode: "",
                modelId: "",
                serialNo: "", // SN码
                matterStatus: "", // 物料状态
                page: 1,
                size: 20,
            },
            pageTotal: 0, // 分页总数

            tableData: {
                columns: [
                    { id: "qrCode", label: "设备编号", width: "100" },
                    { id: "qrCodePath", label: "设备二维码", width: "100" },
                    { id: "belongDeptName", label: "归属部门", width: "100" },
                    { id: "serialNo", label: "SN码", width: "100" },
                    {
                        id: "matterStatus",
                        label: "物料状态",
                        width: "100",
                    },
                    { id: "inTime", label: "入库时间", width: "100" },
                    { id: "userName", label: "入库人", width: "100" },
                    // { id: "flowMethod", label: "入库类型", width: "100" },
                    // { id: "bindingStatus", label: "绑定状态", width: "100" },
                    { id: "checkStatus", label: "检测状态", width: "100" },
                ],
                data: [],
            },
            exportQRcodeVisible: false, // 导出二维码弹窗显示
            row: {}, // 点击导出的行信息
        };
    },

    created() {},

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 点击缩略图的方法
        handleImgClick(url) {
            this.showViewer = true;
            this.viewerImgList = [url];
        },

        // 关闭预览图片
        closeViewer() {
            this.showViewer = false;
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getStockBindDeviceTable(
                Object.assign(this.searchForm, {
                    modelId: this.$route.query.modelId,
                })
            )
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 查看
        details(item) {
            this.$router.push({
                path: "/stockManage/deviceInoutRecord",
                query: { modelId: item.modelId, empId: item.id },
            });
        },
        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 导出
        exportQR(item) {
            this.row = item;
            this.exportQRcodeVisible = true;
        },
        // 关闭导出二维码弹框
        closeexportQRcode() {
            this.row = "";
            this.exportQRcodeVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 270 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
